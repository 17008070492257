$bebas: 'Bebas Neue';
$bebas-book: 'Bebas Neue Book';

// spessore fonts
$thin: 100;
$extralight: 200;
$light: 300;
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
$extrabold: 800;
$black: 900;
