@font-face {
	font-family: "icons";
	src: url("../../fonts/icons/icons.ttf?qnr0p1") format("truetype"), url("../../fonts/icons/icons.woff?qnr0p1") format("woff"),
		url("../../fonts/icons/icons.svg?qnr0p1#icons") format("svg");
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: "icons" !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-bell:before {
	content: "\e900";
}
.icon-chart:before {
	content: "\e901";
}
.icon-dumbell:before {
	content: "\e902";
}
.icon-home:before {
	content: "\e903";
}
.icon-medal:before {
	content: "\e904";
}
.icon-news:before {
	content: "\e905";
}
.icon-user:before {
	content: "\e906";
}
