/* You can add global styles to this file, and also import other style files */
@import "assets/scss/dependencies/_colors.scss";
@import "assets/scss/dependencies/_fonts.scss";
@import "assets/scss/dependencies/_mixins.scss";
@import "assets/scss/dependencies/_icons.scss";

// Bootstrap Icons
@import "~bootstrap-icons/font/bootstrap-icons.css";

/**
 * BEBAS NEUE
 */

// THIN
@font-face {
	font-family: $bebas;
	src: url("./assets/fonts/bebas-neue/thin/BebasNeuePro-Thin.woff2") format("woff2"),
		url("./assets/fonts/bebas-neue/thin/BebasNeuePro-Thin.woff") format("woff"),
		url("./assets/fonts/bebas-neue/thin/BebasNeuePro-Thin.ttf") format("truetype");
	font-weight: $thin;
	font-style: normal;
}

// EXTRALIGHT
@font-face {
	font-family: $bebas;
	src: url("./assets/fonts/bebas-neue/light/BebasNeuePro-Light.woff2") format("woff2"),
		url("./assets/fonts/bebas-neue/light/BebasNeuePro-Light.woff") format("woff"),
		url("./assets/fonts/bebas-neue/light/BebasNeuePro-Light.ttf") format("truetype");
	font-weight: $extralight;
	font-style: normal;
}

// LIGHT
@font-face {
	font-family: $bebas;
	src: url("./assets/fonts/bebas-neue/book/BebasNeuePro-Book.woff2") format("woff2"),
		url("./assets/fonts/bebas-neue/book/BebasNeuePro-Book.woff") format("woff"),
		url("./assets/fonts/bebas-neue/book/BebasNeuePro-Book.ttf") format("truetype");
	font-weight: $light;
	font-style: normal;
}

// REGULAR
@font-face {
	font-family: $bebas;
	src: url("./assets/fonts/bebas-neue/regular/BebasNeuePro-Regular.woff2") format("woff2"),
		url("./assets/fonts/bebas-neue/regular/BebasNeuePro-Regular.woff") format("woff"),
		url("./assets/fonts/bebas-neue/regular/BebasNeuePro-Regular.ttf") format("truetype");
	font-weight: $regular;
	font-style: normal;
}

// BOLD
@font-face {
	font-family: $bebas;
	src: url("./assets/fonts/bebas-neue/bold/BebasNeuePro-Bold.woff2") format("woff2"),
		url("./assets/fonts/bebas-neue/bold/BebasNeuePro-Bold.woff") format("woff"),
		url("./assets/fonts/bebas-neue/bold/BebasNeuePro-Bold.ttf") format("truetype");
	font-weight: $bold;
	font-style: normal;
}


:root {
	--notch-top: var(--notch-inset-top, env(safe-area-inset-top, 0px));
	--notch-left: var(--notch-inset-left, env(safe-area-inset-left, 0px));
	--notch-right: var(--notch-inset-right, env(safe-area-inset-right, 0px));
	--notch-bottom: var(--notch-inset-bottom, env(safe-area-inset-bottom, 0px));
}

html,
body {
	padding: 0;
	margin: 0;
}

* {
	box-sizing: border-box;
	font-family: $bebas;
	font-weight: $regular;
}

input,
textarea {
	font-weight: $light;
}

a {
	text-decoration: none;
}

figure {
	margin: 0;
}

body {
	background: $blu-scuro;
	height: 100vh;
}

.button {
	display: block;
	@include reset();
	border: none;
	background: #ffffff;
	color: #000000;
	width: 100%;
	padding: 15px 25px;
	font-size: 20px;
	@include radius(10px);
	cursor: pointer;
	text-align: center;
}

.row-title {
	position: relative;
	color: #595b66;
	text-align: center;
	padding: 15px 0;
	font-size: 27px;
	font-weight: $bold;

	.back {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: 20px;
	}
}

.card {
	position: relative;
	overflow: hidden;
	border-radius: 20px;
	margin: 15px 10px;
	color: white;
	text-align: center;

	&.active {
		border: 2px solid $accent;
	}

	.card-title {
		position: relative;
		background: $blu;
		padding: 15px 0;
		font-size: 20px;
		font-weight: $regular;
		margin: 0;
	}

	.card-body {
		background: #323546;
		padding: 15px;
		font-size: 18px;
		text-transform: none;
		font-weight: $regular;
		letter-spacing: 0.75px;
	}
}

.list-group {
	margin: 0;
	padding: 0;
	list-style: none;

	.list-group-item {
		display: flex;
		align-items: center;
		position: relative;
		background: #323546;
		border-radius: 20px;
		margin: 10px;
		overflow: hidden;
		padding: 10px;
		color: white;

		&.active {
			border: 2px solid $accent;
		}

		.item-left {
			width: 64px;
			flex-shrink: 0;
			margin-right: 10px;
		}

		.item-main {
			width: 100%;
			margin-right: 10px;
			overflow: hidden;
		}

		.item-right {
			margin-right: 10px;
		}
	}
}

/* ink bar style */
.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
	background: white;
}

/* label style */
.mat-tab-label {
	color: white;
	font-family: $bebas;
	font-weight: $light;
	font-size: 22px;
}

/* focus style */
.mat-tab-group.mat-primary .mat-tab-label:not(.mat-tab-disabled):focus,
.mat-tab-group.mat-primary .mat-tab-link:not(.mat-tab-disabled):focus,
.mat-tab-nav-bar.mat-primary .mat-tab-label:not(.mat-tab-disabled):focus,
.mat-tab-nav-bar.mat-primary .mat-tab-link:not(.mat-tab-disabled):focus {
	background: #272a37;
}

/* arrows */
.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
	border-color: rgba($color: #fff, $alpha: 0.75);
}

.mat-tab-header-pagination-chevron {
	border-color: #fff;
}

input.is-invalid {
	border-color: #f86c6b !important;
	padding-right: calc(1.5em + 0.75rem) !important;
	background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23f86c6b' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23f86c6b' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") !important;
	background-repeat: no-repeat !important;
	background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) !important;
	background-position: right calc(0.375em + 0.1875rem) center !important;
}

.checkbox.is-invalid {
	span {

		// color: red;
		&::before {
			border-color: red !important;
		}
	}
}

input,
textarea {
	&::placeholder {
		color: #b3b2b2 !important;
	}
}

.logo {
	display: inline-block;
	position: relative;
	box-sizing: border-box;
	width: 64px;
	height: 64px;
	background: #242633;
	@include rounded-hex($main-radius: 5%, $rounding-radius: 20%, $rotated: false);

	img {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: calc(100% - 20px);
	}
}

.avatar {
	display: inline-block;
	position: relative;
	box-sizing: border-box;
	width: 64px;
	height: 64px;
	background: #242633;
	@include rounded-hex($main-radius: 5%, $rounding-radius: 20%, $rotated: false);

	img {
		width: 100%;
	}
}

* {
	text-transform: uppercase;
	letter-spacing: 0.5px;
}

form {

	input[type="text"],
	input[type="email"],
	input[type="password"],
	input[type="number"],
	select,
	textarea {
		text-transform: none !important;

		&::placeholder {
			text-transform: none;
		}
	}
}
